import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import sanitizeHtml from 'sanitize-html';
import { useDispatch, useSelector } from 'react-redux';
import { setCompletedTasks, setTaskUncompleted } from '../../redux/reducers/tasks';
import { useFirebase } from '../../context/firebase';
import firebaseT from 'firebase';
import {CommentList} from '../Comments/CommentList';
import T from '../../translations/T';
import { LanguageContext } from '../../LanguageContext';
import { changeCourseStatusFromCompleteToInProgress } from '../../redux/reducers/courses';

export const TaskContent = ({currentTask, isCompleted, onTaskComplete}) => {
  // const contentHtml = sanitizeHtml(currentTask.content['content_en']);
  const [languagesContext] = useContext(LanguageContext);
  const contentKey = `content_${languagesContext}`;
  const hintKey = `hint_${languagesContext}`;
  const nameKey = `name_${languagesContext}`;
  const [openHint, setOpenHint] = useState(false);

  const sanitizer = html => {
    return sanitizeHtml(html, {
      allowedTags: [ 'div', 'p', 'em', 'strong', 'iframe', 'br', 'ul', 'ol', 'li', 'a', 'table', 'tbody', 'td', 'tr', 'img', 'code' ],
      allowedAttributes: {
        iframe: ['src'],
        a: ['href', 'target', 'rel'],
        div: ['class', 'onclick'],
        img: ['src']
      },
      transformTags: {
        'a': sanitizeHtml.simpleTransform('a', {target: '_blank', rel: 'noopener'}),
      },
      allowedIframeHostnames: ['www.youtube.com', 'player.vimeo.com']
    });
  }

  const clean = sanitizer(currentTask.content[contentKey]);
  const cleanHint = sanitizer(currentTask.content[hintKey]);

  const firebase = useFirebase();
  const currentUser = useSelector(state => state.user.currentUser);
  const dispatch = useDispatch();
  const completedCourses = useSelector(state => state.courses.completed);
  const inProgressCourses = useSelector(state => state.courses.inProgress);

  const makeTaskDone = () => {
    firebase.firestore.collection("users").doc(currentUser.id).update({
        tasksCompleted: firebaseT.firestore.FieldValue.arrayUnion(currentTask.id)
    }).then(() => {
      dispatch(setCompletedTasks([currentTask.id]));
      onTaskComplete();
    });
  }

  const makeTaskUnDone = () => {
    firebase.firestore.collection("users").doc(currentUser.id).update({
        tasksCompleted: firebaseT.firestore.FieldValue.arrayRemove(currentTask.id)
    }).then(() => {
      dispatch(setTaskUncompleted(currentTask.id));
    });

    const courseId = currentTask.courseId;
    firebase.firestore.collection("users").doc(currentUser.id).set({
        coursesCompleted: completedCourses.filter(course => course !== courseId),
        coursesInProgress: [...inProgressCourses, courseId]
    }, { merge: true }).then(() => {
        dispatch(changeCourseStatusFromCompleteToInProgress(courseId));
    });
  }

  return (
    <div className="course-content" c>
      <Typography variant="h5">
        {currentTask.content[nameKey]}
      </Typography>
      <Typography variant="body1">
        <div dangerouslySetInnerHTML={{__html: clean}} className="main-content"></div>
      </Typography>
      {currentTask.content[hintKey] ? 
        <div className={`hint ${openHint ? 'open-body' : ''}`} onClick={() => {setOpenHint(!openHint)}}>
          <div className="hint-title"><T ts="hint"/></div>
          <div className="hint-body">
            <div dangerouslySetInnerHTML={{__html: cleanHint}}></div>
          </div>
        </div> : null}
      <div>
        {!isCompleted ? 
          <Button variant="contained" color="primary" onClick={makeTaskDone}><T ts="completeTask"/></Button>
          :<Button variant="contained" color="default" onClick={makeTaskUnDone}><T ts="setTaskIncomplete"/></Button> }
      </div>
      <div style={{paddingBottom: '20px'}}>
        <CommentList currentTask={currentTask}/>
      </div>
    </div>
  );
}