export const getTranslation = (lang, key) => {
  lang = lang || "en";
  let translation = strings[lang][key];
  if (!translation) {
    translation = strings["en"][key];
  }
  if (!translation) {
    translation = key;
  }
  return translation;
};

const strings = {
  en: {
    home: "Courses",
    english: "English",
    russian: "Russian",
    hey: "Hey",
    logout: "Logout",
    goToCourse: "Go To Course",
    takeThisCourse: "Take This Course",
    coursesInProgress: "Courses in progress",
    availableCourses: "Available courses",
    completedCourses: "Completed courses",
    noProgressCourses: "You haven't took any course yet",
    noAvailableCourses: "Congratulations, you have finished all available courses!",
    noCompletedCourses: "You haven't accomplished any course yet",
    completeTask: "Mark task as Completed",
    setTaskIncomplete: "Mark Task as Incomplete",
    replyTo: "Reply to",
    editComment: "Edit comment",
    writeComment: "Write a comment",
    linkLabel: "Link (only from: github.com, codepen.io, jsfiddle.net, codesandbox.io)",
    yourMessage: "Your message",
    whitelistLinksSupport: "We only support links only from",
    linkIsNotValid: "Link is not valid",
    reply: "Reply",
    send: "Send",
    edit: "Edit",
    cancel: "Cancel",
    congrats: "Congratulations!",
    completeCourseMessage: "You have finished the course!",
    takeAnotherCourse: "Take another course",
    hint: "Hint",
    articles: "Articles",
    practice: "Practice",
    theory: "Theory",
    all: "All",
    yourProgress: "Your progress",
  },
  ru: {
    home: "Курсы",
    english: "English",
    russian: "Русский",
    hey: "Привет",
    logout: "Выйти",
    goToCourse: "К Заданиям",
    takeThisCourse: "Взять курс",
    coursesInProgress: "Курсы в процессе",
    availableCourses: "Доступные Курсы",
    completedCourses: "Пройденные курсы",
    noProgressCourses: "Вы еще не взяли ни одного курса",
    noAvailableCourses: "Поздравляем! Вы прошли все курсы!",
    noCompletedCourses: "Вы пока не прошли ни одного курса",
    completeTask: "Отметить Задание как выполненную",
    setTaskIncomplete: "Сделать Задачу Невыполненной",
    replyTo: "Ответить",
    editComment: "Редактировать комментарий",
    writeComment: "Добавить комментарий",
    linkLabel: "Ссылка (только с: github.com, codepen.io, jsfiddle.net, codesandbox.io)",
    yourMessage: "Ваше сообщение",
    whitelistLinksSupport: "Мы поддерживаем ссылки только с",
    linkIsNotValid: "Неправильный линк",
    reply: "Ответить",
    send: "Послать",
    edit: "Отредактировать",
    cancel: "Отмена",
    congrats: "Поздравляем!",
    completeCourseMessage: "Вы закончили этот курс!",
    takeAnotherCourse: "Взять другой курс",
    hint: "Подсказка",
    articles: "Статьи",
    practice: "Практика",
    theory: "Теория",
    all: "Все",
    yourProgress: "Ваш прогресс",
  },
};
