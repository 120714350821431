export const whitelistDomainsForComments = [
    'github.com',
    'www.github.com',
    'codepen.io',
    'www.codepen.io',
    'jsfiddle.net',
    'www.jsfiddle.net',
    'codesandbox.io',
    'www.codesandbox.io'
]

export function isValidHttpUrl(string) {
    let url;
    
    try {
      url = new URL(string);
    } catch (_) {
      return false;  
    }
  
    return url.protocol === "http:" || url.protocol === "https:";
  }