import React, { useState } from "react";
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { appAuth } from '../../context/firebase';
import Avatar from '@material-ui/core/Avatar';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: '#ff7062',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const ForgotPassword = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorLink, setErrorLink] = useState(null);
  const [successMessageShow, setSuccessMessageShow] = useState(null);
  const [formValues, setFormValues] = useState({
    email: "",
  });
  const classes = useStyles();

  const onSubmitClick = () => {
    setIsSubmitting(true);
    setErrorLink(null);
    appAuth.sendPasswordResetEmail(formValues.email)
        .then(function () {
            setIsSubmitting(false);
            setSuccessMessageShow('Please check your email!');
        }).catch(function (e) {
            setIsSubmitting(false);
            setErrorLink(e.message);
        }) 
  }

  const handleChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  const formPreventDefault = (e) => {
    e.preventDefault();
  }

  

  return (
    <Container component='main' maxWidth='xs'>
      <CssBaseline />
      <div><img className={"logo-image"} src={require('../../static/assets/logo-black.png')} alt="" style={{width: '40%', position: 'relative', top: '3px'}}/></div>
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component='h1' variant='h5'>
          FORGOT PASSWORD?
        </Typography>
        <hr></hr>
        {!successMessageShow && <div>
            <Typography variant='body1'>
            Plase enter your email and we will send you a link to reset your password
            </Typography>
            <form className={classes.form} noValidate onSubmit={formPreventDefault}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                <TextField
                    onChange={handleChange}
                    variant='outlined'
                    fullWidth
                    id='email'
                    label='Email'
                    name='email'
                    autoComplete='none'
                    value={formValues.email}
                />
                </Grid>
            </Grid>
            <Button
                onClick={onSubmitClick}
                type='submit'
                variant='contained'
                color='primary'
                className={classes.submit}
                disabled={isSubmitting}
                >
                    Send
                </Button>
            </form>
        </div>}
        <div style={{color: 'green'}}>
            <Typography variant='body1'>{successMessageShow}</Typography>
        </div>
        <div style={{color: 'red'}}>{ errorLink }</div>
      </div>
    </Container>
  );
}

export default ForgotPassword;