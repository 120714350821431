import React, { useEffect } from 'react';
import { CommentForm } from './CommentForm';
import { useSelector, useDispatch } from 'react-redux';
import { readComments, setFetchedCommentTasks } from '../../redux/reducers/comments';
import { useFirebase } from '../../context/firebase';
import { CommentSingle } from './CommentSingle';

export const CommentList = ({currentTask}) => { 

    const comments = useSelector(state => state.comments.list);
    // console.log(comments);
    const fetchedCommentTasks = useSelector(state => state.comments.fetchedCommentTasks);
    const dispatch = useDispatch();
    const firebase = useFirebase();
    const taskComments = comments.filter(comment => comment.taskId === currentTask.id && !comment.parentComment)
        .sort((a, b) => parseInt(b.timestamp) - parseInt(a.timestamp));

    useEffect(() => {
        if(!fetchedCommentTasks.includes(currentTask.id)) {
            // console.log('FETCHING COMMENTS...')
            firebase.firestore.collection("comments").get().then((querySnapshot) => {
              const comments = [];
              querySnapshot.forEach((doc) => {
                comments.push({...doc.data(), id: doc.id});
              });
              dispatch(readComments(comments));
              dispatch(setFetchedCommentTasks(currentTask.id));
            });
          }
    }, [currentTask.id]);

    const getRepliedComments = (parentComment) => {
        comments.filter(comment => {
           return comment.taskId === currentTask.id && comment.parentComment === parentComment.id;
        });
        return comments.filter(comment => comment.taskId === currentTask.id && comment.parentComment === parentComment.id).sort((a, b) => parseInt(a.timestamp) - parseInt(b.timestamp));
    }

  return (
    <div style={{paddingTop: '15px'}}>
      <CommentForm currentTask={currentTask} />
      <div>{taskComments.map(comment => (
        <div>
            <CommentSingle comment={comment} currentTask={currentTask}/>
            <div style={{paddingLeft: '5%'}}>
                {getRepliedComments(comment).map(repliedComment => (<CommentSingle comment={repliedComment} currentTask={currentTask} parentComment={comment}/>))}
            </div>
        </div>
      ))}</div>
    </div>
  );
}