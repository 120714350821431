import { createSlice } from '@reduxjs/toolkit'

export const commentsSlice = createSlice({
  name: 'courses',
  initialState: {
    list: [],
    fetchedCommentTasks: [],
  },
  reducers: {
    readComments: (state, action) => {
      state.list = [...action.payload];
    },
    addComment: (state, action) => {
      state.list.push(action.payload);
    },
    editComment: (state, action) => {
      const index = state.list.findIndex(comment => comment.id === action.payload.id);
      if(index > -1) {
        state.list.splice(index, 1, action.payload);
      }
    },
    deleteComment: (state, action) => {
      const index = state.list.findIndex(comment => comment.id === action.payload.id);
      if(index > -1) {
        state.list.splice(index, 1);
      }
    },
    setFetchedCommentTasks: (state, action) => {
      state.fetchedCommentTasks.push(action.payload);
    },
  },
})

// Action creators are generated for each case reducer function
export const { readComments, addComment, setFetchedCommentTasks, editComment, deleteComment } = commentsSlice.actions;

export default commentsSlice.reducer