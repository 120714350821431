import { configureStore } from '@reduxjs/toolkit'
import coursesReducer from './redux/reducers/courses';
import tasksReducer from './redux/reducers/tasks';
import userAccomplishmentsReducer from './redux/reducers/userAccomplishments';
import comments from './redux/reducers/comments';
import user from './redux/reducers/user';
import thunk from 'redux-thunk';

const store = configureStore({
  reducer: {
    courses: coursesReducer,
    tasks: tasksReducer,
    userAccomplishments: userAccomplishmentsReducer,
    user: user,
    comments: comments
  },
  middleware: [thunk]
})
// export type RootState = ReturnType<typeof store.getState>

export default store;