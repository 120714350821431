import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { relative } from 'path';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100vh',
    marginTop: '65px',
    backgroundColor: '#ffffff',
    position: 'relative'
  },
  loader: {
    position: 'absolute',
    top: '40%',
    left: '50%',
    // transform: 'translate(-50%, -50%)'
  }
}));

export default function Loader() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CircularProgress className={classes.loader} />
    </div>
  );
}