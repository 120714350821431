import { createSlice } from '@reduxjs/toolkit'

export const coursesSlice = createSlice({
  name: 'courses',
  initialState: {
    // list: [
    //     {
    //         name: 'Layout',
    //         imageUrl: 'https://codeway.today/wp-content/uploads/2021/06/Depositphotos_99925548_l-2015-1.jpeg',
    //         description: 'Full path to learn layout. HTML, CSS, SCSS and tasks for practice.',
    //         time: '14-30 days',
    //         comments: 2,
    //         id: 'a123',
    //         tasksQty: 10
    //     },
    //     {
    //         name: 'Javascript',
    //         imageUrl: 'https://codeway.today/wp-content/uploads/2021/05/Depositphotos_123916348_s-2019.jpg',
    //         description: 'Full path to learn Javascript and prepare to learning JS frameworks.',
    //         time: '20-30 days',
    //         comments: 2,
    //         tasksQty: 15
    //     },
    //     {
    //         name: 'JS Exersices',
    //         imageUrl: 'https://codeway.today/wp-content/uploads/2020/11/Depositphotos_134109038_l-2015.jpg',
    //         description: 'JS exersices to practise fluency and to get use to standard patterns of coding',
    //         time: '2-5 days',
    //         comments: 2,
    //         tasksQty: 9
    //     }
    // ],
    list: [],
    inProgress: [],
    completed: []
  },
  reducers: {
    readCourses: (state, action) => {
      state.list = [...action.payload];
    },
    setCoursesInProgress: (state, action) => {
      state.inProgress = [...action.payload];
    },
    setCoursesCompleted: (state, action) => {
      state.completed = [...action.payload];
    },
    changeCourseStatusToComplete: (state, action) => {
      state.inProgress = state.inProgress.filter(course => course !== action.payload);
      state.completed.push(action.payload);
    },
    changeCourseStatusFromCompleteToInProgress: (state, action) => {
      state.completed = state.completed.filter(course => course !== action.payload);
      state.inProgress.push(action.payload);
    },
  },
})

// Action creators are generated for each case reducer function
export const { readCourses, setCoursesInProgress, setCoursesCompleted, changeCourseStatusToComplete, changeCourseStatusFromCompleteToInProgress } = coursesSlice.actions;

export default coursesSlice.reducer