import React, { useEffect } from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import {useMediaQuery } from '../../hooks/mediaQuery';

export const LoggedOutHome = () => {
    const isMobileView = useMediaQuery('(max-width: 768px)');

    return (
        <Container component='div' maxWidth='xl' style={{backgroundColor: '#3f51b5', padding: isMobileView ? '50px 0' : '100px 0'}}>
            <Container component='div' maxWidth='lg'>
                <Typography component='h1' variant={isMobileView ? 'h4' : 'h3'} style={{textAlign: 'center', color: '#ffffff', paddingBottom: '20px'}}>
                    Why do you need to Login?
                </Typography>
                <Typography variant="body1" component="p" style={{textAlign: 'center', color: '#ffffff'}}>
                Our learning curve is not just a set of separate tutorials, we have a full path from scratch containing exactly what you need to do to become a successful developer. We have created a mix of theoretical courses and exercises to practice, develop and master your skills. At the end of your learning curve you will become eligible to apply for a job and to take part in real projects. We have a positive approach due to the fact that we love to share our experience with people who are really looking for it. And don't hesitate to catch the moment while this project is absolutely FREE!  "So why is this free?", you would ask. The answer is very simple: this is our contribution to IT community!
                </Typography>
            </Container>
        </Container>
    ) 
}