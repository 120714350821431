import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import ReplyIcon from '@material-ui/icons/Reply';
import { useSelector, useDispatch } from 'react-redux';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { CommentForm } from './CommentForm';
import { useFirebase } from '../../context/firebase';
import { deleteComment } from '../../redux/reducers/comments';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100%',
    marginBottom: '10px'
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

export const CommentSingle = ({comment, currentTask, parentComment}) => {
  const classes = useStyles();
  const [openReplyForm, setOpenReplyForm] = useState(false);
  const [openEditForm, setOpenEditForm] = useState(false);
  const currentUser = useSelector(state => state.user.currentUser);

  const firebase = useFirebase();
  const dispatch = useDispatch();

  const date = new Date(comment.timestamp);
  let dateString = date.toDateString();
  let localizedDateString = dateString.split(' ');
  localizedDateString.shift();
  localizedDateString[1] = localizedDateString[1] + ',';
  localizedDateString = localizedDateString.join(' ');
  if(comment.edited) {
    localizedDateString = `${localizedDateString} (edited)`;
  }

  const removeComment = () => {
    firebase.firestore.collection("comments").doc(comment.id).delete().then(() => {
        // console.log("Document successfully deleted!");
        dispatch(deleteComment(comment));
    }).catch((error) => {
        console.error("Error removing document: ", error);
    });
  }

  const editComment = () => {
    setOpenEditForm(true);
  }

  const replyComment = () => {
    setOpenReplyForm(true);
  }
  
  const closeReplyForm = () => {
    setOpenReplyForm(false);
  }

  const closeEditForm = () => {
    setOpenEditForm(false);
  }

  

  return (
    <div>
        <Card className={classes.root}>
            <CardHeader
                avatar={
                <Avatar aria-label="recipe" className={classes.avatar}>
                    {comment.userName && comment.userName.split('')[0]}
                </Avatar>
                }
                action={
                <div>
                    <IconButton aria-label="settings">
                        <ReplyIcon onClick={replyComment} />
                    </IconButton>
                    { comment.userId === currentUser.id ? <IconButton aria-label="settings">
                        <EditIcon onClick={editComment}/>
                    </IconButton> : null }
                    { comment.userId === currentUser.id ? <IconButton aria-label="settings">
                        <DeleteIcon onClick={removeComment}/>
                    </IconButton> : null }
                </div>
                }
                title={comment.userName}
                subheader={localizedDateString}
            />
            <CardContent>
                <Typography variant="body1" color="textSecondary" component="p">
                    <a target="_blank" href={comment.link}>{comment.link}</a>
                </Typography>
                <Typography variant="body1" color="textSecondary" component="p">
                {comment.message}
                </Typography>
            </CardContent>
        </Card>
        {openEditForm && <CommentForm currentTask={currentTask} editingComment={comment} closeEditForm={closeEditForm}/>}
        {openReplyForm && <CommentForm currentTask={currentTask} replyComment={parentComment || comment} closeReplyForm={closeReplyForm}/>}
    </div>
  );
}
