import React, { useEffect, useState, useContext } from "react";
import { useHistory, Link } from "react-router-dom";
import { useAuthentication } from "../../context/authentication";
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { whitelistDomainsForComments, isValidHttpUrl } from '../../helpers/consts';
import { useFirebase } from '../../context/firebase';
import { useDispatch, useSelector } from 'react-redux';
import { addComment, editComment } from '../../redux/reducers/comments';
import T from '../../translations/T';
import { LanguageContext } from '../../LanguageContext';
import { getTranslation } from '../../translations/translations';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export const CommentForm = ({currentTask, replyComment, closeReplyForm, editingComment, closeEditForm}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorLink, setErrorLink] = useState(null);
  const [formValues, setFormValues] = useState({
    link: !editingComment ? "" : editingComment.link,
    message: !editingComment ? "" : editingComment.message,
  });
  const classes = useStyles();
  const firebase = useFirebase();
  const currentUser = useSelector(state => state.user.currentUser);
  const dispatch = useDispatch();
  const [languagesContext, setLanguagesContext] = useContext(LanguageContext);

  

  function url_domain(data) {
    const a = document.createElement('a');
    a.href = data;
    return a.hostname;
  }

  const validateLink = (link) => {
    if(!isValidHttpUrl(link)){
      // console.log('URL INVALID');
      setErrorLink(1);
      return false;
    }
    if(!whitelistDomainsForComments.includes(url_domain(link))) {
      setErrorLink(2);
      return false;
    }
    return true;
  }

  const onSubmit = async () => {
    const { link, message } = formValues;
    if(link && !validateLink(link)) {
      return false;
    }
    setErrorLink(null);
    const newComment = {
      link,
      message,
      userId: currentUser.id,
      userName: currentUser.name,
      avatar: "",
      timestamp: Date.now(),
      taskId: currentTask.id,
      courseId: currentTask.courseId
    }
    if(replyComment) { 
      newComment.parentComment = replyComment.id;
    }
    firebase.firestore.collection("comments").add(newComment).then((doc) => {
      dispatch(addComment({...newComment, id: doc.id}));
      setFormValues({
        link: "",
        message: ""
      })
      if(replyComment) { 
        closeReplyForm();
      }
    });    
  };

  const onEditComment = () => {
    const { link, message } = formValues;
    if(link && !validateLink(link)) {
      return false;
    }
    setErrorLink(null);

    const editedComment = {
      ...editingComment,
      link: link,
      message: message,
      edited: true
    }
    firebase.firestore.collection("comments").doc(editedComment.id).set(editedComment, {merge: true}).then((doc) => {
      dispatch(editComment(editedComment));
      closeEditForm();
    });    
  }

  // useEffect(() => {
  //   if(user?.uid) {
  //     push('/');
  //   }
  // }, [user]);

  const handleChange = (e) => {
    setErrorLink(null);
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  const formPreventDefault = (e) => {
    e.preventDefault();
  }

  // if(replyComment) {
  //   setFormValues({
  //     ...formValues,
  //     message: `${replyComment.userName}, `,
  //   });
  // }

  const getCommentFormTitle = () => {
    if(replyComment) {
      return <T ts="replyTo"/>;
    } else {
      return editingComment ? <T ts="editComment"/> : <T ts="writeComment"/>;
    }
  }

  const linkLabel = getTranslation(languagesContext, 'linkLabel');
  const textLabel = getTranslation(languagesContext, 'yourMessage');

  return (
    <div style={replyComment ? {paddingLeft: '5%'} : {}}>
      <CssBaseline />
      <div className={classes.paper}>
        {/* <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar> */}
        <Typography component='h1' variant='h5'>
          {getCommentFormTitle()}
        </Typography>
        <form className={classes.form} noValidate onSubmit={formPreventDefault}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                onChange={handleChange}
                variant='outlined'
                fullWidth
                id='link'
                label={linkLabel}
                name='link'
                autoComplete='none'
                value={formValues.link}
              />
            { errorLink === 2 && <span style={{color: 'red'}}><T ts="whitelistLinksSupport"/>: github.com, codepen.io, jsfiddle.net, codesandbox.io</span> }
            { errorLink === 1 && <span style={{color: 'red'}}><T ts="linkIsNotValid"/></span> }
            </Grid>
            <Grid item xs={12}>
            <TextField
                fullWidth
                multiline
                required
                label={textLabel}
                variant='outlined'
                name='message'
                value={formValues.message}
                InputProps={{
                    inputComponent: TextareaAutosize,
                    rows: 3
                }}
                // value={text}
                onChange={handleChange}
            />
            </Grid>
          </Grid>
          {!editingComment ? <Button
            onClick={onSubmit}
            type='submit'
            variant='contained'
            color='primary'
            className={classes.submit}
          >
            {replyComment ? <T ts="reply"/> : <T ts="send"/> }
          </Button> : 
          <Button
          onClick={onEditComment}
          type='submit'
          variant='contained'
          color='primary'
          className={classes.submit}
        >
          <T ts="edit"/>
        </Button>}
          {replyComment || editingComment ? <Button
            style={{marginLeft: '15px'}}
            onClick={replyComment ? closeReplyForm : closeEditForm}
            variant='contained'
            color='default'
            className={classes.submit}
          >
            <T ts="cancel"/>
          </Button> : null }
        </form>
      </div>
    </div>
  );
}