import React, { useEffect, useContext } from 'react';
import { useAuthentication } from "../context/authentication";
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { Container, Box } from '@material-ui/core';
import { useFirebase } from '../context/firebase';
import { useDispatch, useSelector } from 'react-redux';
import { setUser, updateUserLaguage } from '../redux/reducers/user';
import { setCoursesInProgress, setCoursesCompleted } from '../redux/reducers/courses';
import { setCompletedTasks } from '../redux/reducers/tasks';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Icon from '@material-ui/core/Icon';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { LanguageContext } from '../LanguageContext';
import T from '../translations/T';
import { useMediaQuery } from '../hooks/mediaQuery';
import MenuIcon from '@material-ui/icons/Menu';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  link: {
    textDecoration: 'none'
  },
  button: {
    textTransform: 'inherit',
    fontWeight: 500,
    color: '#fff',
    textDecoration: 'none',
    marginLeft: 10
  },
  menuButton: {
    marginRight: theme.spacing(1),
  },
  title: {
    flexGrow: 4,
  },
}));

const Navbar = () => {
  const classes = useStyles();
  const { push } = useHistory();
  const { doLogout, user }: { doLogout: any, user: any } = useAuthentication();
  const firebase = useFirebase();
  const dispatch = useDispatch();
  const currentUser = useSelector((state: any) => state.user.currentUser);
  const [languageMenu, setLanguageMenu] = React.useState(null);
  const [userMenu, setUserMenu] = React.useState(null);
  const [openMobileMenu, setOpenMobileMenu] = React.useState(null);

  const [languagesContext, setLanguagesContext] = useContext(LanguageContext);
  const isMobileView = useMediaQuery('(max-width: 768px)');

  const handleLanguageMenuOpen = (event: any) => {
    setLanguageMenu(event.currentTarget);
  };

  const handleLanguageMenuClose = () => {
    setLanguageMenu(null);
  };

  const handleLanguageMenuClick = (language: string) => {
    setLanguagesContext(language);
    setLanguageMenu(null);
    // dispatch(updateUserLaguage(language));
    firebase.firestore.collection("users").doc(currentUser.id).set({
      userLanguage: language
    }, { merge: true }).then(() => {
      // console.log('USER LANG UPDATED');

    });
  };

  const handleUserMenuOpen = (event: any) => {
    setUserMenu(event.currentTarget);
  };

  const handleUserMenuClose = () => {
    setUserMenu(null);
  };

  const logout = async () => {
    setUserMenu(null);
    await doLogout();
    // push("/");
    window.location.href = "/";
  };

  useEffect(() => {
    if (!currentUser) {
      firebase.firestore.collection("users").doc(user.uid).get().then((doc: any) => {
        // console.log(doc.data(), doc.id);
        const userToSave: any = { ...doc.data(), id: doc.id };
        dispatch(setUser(userToSave));
        userToSave.coursesInProgress?.length && dispatch(setCoursesInProgress(userToSave.coursesInProgress));
        userToSave.coursesCompleted?.length && dispatch(setCoursesCompleted(userToSave.coursesCompleted));
        userToSave.tasksCompleted?.length && dispatch(setCompletedTasks(userToSave.tasksCompleted));
      });
    }
  }, [user]);

  const handleMobileMenuOpen = (event: any) => {
    setOpenMobileMenu(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setOpenMobileMenu(null);
  };

  return (
    <div className={classes.root} style={{ height: '64px' }}>
      <AppBar position='fixed'>
        <Container fixed style={{ paddingRight: isMobileView ? 0 : '16px' }}>
          <Toolbar style={{ paddingRight: isMobileView ? 0 : '16px' }}>
            <Typography variant='h6' className={classes.title}>
              <Link to="/"><img src={require('../static/assets/logo_1.png')} alt="" style={{ height: isMobileView ? '31px' : '35px', position: 'relative', top: '3px' }} /></Link>
            </Typography>

            {!isMobileView && <Box>
              <Button
                component={Link}
                to='/'
                className={classes.button}
              >
                <T ts="home" />
              </Button>
              <a href="https://codeway.today" target="_blank" className={classes.link}>
                <Button
                  className={classes.button}
                >
                  <T ts="articles" />
                </Button>
              </a>
            </Box>
            }

            <Box>
              <Button aria-controls="languages" aria-haspopup="true" onClick={handleLanguageMenuOpen} className={classes.button}>
                {languagesContext === 'en' ? <T ts="english" /> : <T ts="russian" />} <ArrowDropDownIcon />
              </Button>
              <Menu
                id="languages"
                anchorEl={languageMenu}
                keepMounted
                open={Boolean(languageMenu)}
                onClose={handleLanguageMenuClose}
              >
                <MenuItem onClick={() => handleLanguageMenuClick('en')}><T ts="english" /></MenuItem>
                <MenuItem onClick={() => handleLanguageMenuClick('ru')}><T ts="russian" /></MenuItem>
              </Menu>
            </Box>
            {!isMobileView && currentUser && <Box>
              <Button aria-controls="user-menu" aria-haspopup="true" onClick={handleUserMenuOpen} className={classes.button}>
                <T ts="hey" />, {currentUser.name} <ArrowDropDownIcon />
              </Button>
              <Menu
                id="user-menu"
                anchorEl={userMenu}
                keepMounted
                open={Boolean(userMenu)}
                onClose={handleUserMenuClose}
              >
                <MenuItem onClick={logout}><T ts="logout" /></MenuItem>
              </Menu>
            </Box>}
            {!isMobileView && <Box mr={3}>
              <Button
                target="_blank" href="https://www.patreon.com/codeway_today"
                className={classes.button}
                style={{ background: '#ff7062' }}
              >
                <T ts="Donate" />
              </Button>
            </Box>}
            {isMobileView && <Box>
              <Button aria-controls="mobileMenu" aria-haspopup="true" onClick={handleMobileMenuOpen} className={classes.button}>
                <MenuIcon />
              </Button>
              <Menu
                id="mobileMenu"
                anchorEl={openMobileMenu}
                keepMounted
                open={Boolean(openMobileMenu)}
                onClose={handleMobileMenuClose}
              >
                <MenuItem onClick={() => { push("/"); handleMobileMenuClose(); }}><T ts="home" /></MenuItem>
                <MenuItem onClick={() => { window.open('https://www.patreon.com/codeway_today', '_newtab'); handleMobileMenuClose(); }}><T ts="Donate" /></MenuItem>
                <MenuItem onClick={() => { logout(); handleMobileMenuClose(); }}><T ts="logout" /></MenuItem>
              </Menu>
            </Box>}

          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
};

export default Navbar;