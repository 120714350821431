import { createSlice } from '@reduxjs/toolkit'

export const tasksSlice = createSlice({
  name: 'tasks',
  initialState: {
      list: [],
      fetchedCourses: [],
    // list: [
    //     {
    //         name: 'Intro',
    //         content: {
    //             en: [
    //                 {
    //                     type: 'title',
    //                     content: "Introduction"
    //                 },
    //                 {
    //                     type: 'htmlText',
    //                     content: "<p> Javascript is needed for different purposes, and its specificity varies slightly from one direction to another: <br> 1) For site layout <br> 2) For building web applications of varying complexity <br> 3) For the server side of applications ( Node.js) <br> 4) For working with libraries of complex animation, 3D modeling, HTML canvas and more. <br> So what's the difference? As a matter of fact, you may need all of the above on your way. Therefore, it makes sense to learn the basics of Javascript, and then delve into the specifics of the direction you need at the moment. </p>"
    //                 },
    //                 {
    //                     type: 'title',
    //                     content: 'Tutorial Parts'
    //                 },
    //                 {
    //                     type: 'htmlText',
    //                     content: '<p>We will not waste time on assumptions, descriptions and tasks of the javascript, it can be read in other sources as well.</p> <p> Learning javascript is not an easy process, you need to be patient and do everything according to the list. <br> 1) Basics <br> 2) DOM <br> 3) JS for frameworks <br> 4) Advanced: Event loop <br> 5) Tasks <br> </p>',
    //                     description: 'Javascript basics'
    //                 }
    //             ]
    //         },
    //         comments: [],
    //         id: 'a123t',
    //         courseId: 'a123'
    //     },
    //     {
    //         name: 'Basics - Tutorials',
    //         content: {
    //             en: [
    //                 {
    //                     type: 'htmlText',
    //                     content: '<p>All the basics of javascript are very easy to learn from this online tutorial. <a href="https://javascript.info/">https://javascript.info</a> it also has practice in almost every chapter. I also recommend checking out the basics tutorial.'
    //                 },
    //                 {
    //                     type: 'youtubeVideo',
    //                     content: 'https://www.youtube.com/embed/PkZNo7MFNF',
    //                     description: 'Javascript basics'
    //                 },
    //                 {
    //                     type: 'youtubeVideo',
    //                     content: 'https://www.youtube.com/embed/Bluxbh9CaQ0',
    //                     description: 'Javascript basics - tutorial in Russian'
    //                 }
    //             ]
    //         },
    //         comments: [],
    //         id: 'a123ty',
    //         courseId: 'a123'
    //     }
    // ],
    completedTasks: [],
  },
  reducers: {
    readTasks: (state, action) => {
      state.list = [...state.list, ...action.payload];
    },
    mergeTasksContent: (state, action) => {
        const mergedTasks = state.list.map(task => {
            const content = action.payload.data.find(contentTask => contentTask.task_id === task.id);
            if(content) {
                task.content = content;
            }
            return task;
        });
        state.list = mergedTasks;
        if(mergedTasks.length){ 
            // mergedTasks.sort((a, b) => parseFloat(a.price) - parseFloat(b.price));
            state.fetchedCourses = [...state.fetchedCourses, action.payload.courseId];
        }
    },
    setCompletedTasks: (state, action) => {
      state.completedTasks = state.completedTasks.concat(action.payload);
    },
    setTaskUncompleted: (state, action) => {
      state.completedTasks = state.completedTasks.filter(task => task !== action.payload);
    }
  },
})

// Action creators are generated for each case reducer function
export const { readTasks, mergeTasksContent, setCompletedTasks, setTaskUncompleted } = tasksSlice.actions

export default tasksSlice.reducer