import React, {useContext} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useHistory } from "react-router-dom";
import { useFirebase } from '../../context/firebase';
import { useAuthentication } from '../../context/authentication';
import { useSelector } from 'react-redux';
import { LanguageContext } from '../../LanguageContext';
import T from '../../translations/T';
import { Chip } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    maxWidth: '100%',
  },
  media: {
    height: 200,
  },
});

export default function MediaCard(props: any) {
  const classes = useStyles();
  const { push } = useHistory();
  const firebase = useFirebase();
  const currentUser = useSelector((state: any) => state.user.currentUser);
  const [languagesContext] = useContext(LanguageContext);
  const descriptionKey = `description_${languagesContext}`;
  const nameKey = `name_${languagesContext}`;

  const goToCourse = () => {
    push(`/course/${props.id}`)
  }

  const takeCourse = () => {
    const coursesInProgress: any = new Set(currentUser.coursesInProgress || []);
    coursesInProgress.add(props.id);
    firebase.firestore.collection("users").doc(currentUser.id).set({
      coursesInProgress: [...coursesInProgress]
    }, { merge: true }).then(() => {
      push(`/course/${props.id}`);
    });
  }

  return (
    <Card className={classes.root}>
        <CardMedia
          className={classes.media}
          image={props.imageUrl}
          title={props[nameKey]}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {props[nameKey]}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {props[descriptionKey]}
          </Typography>
          <div style={{paddingTop: 5}}>{props?.tags?.map((tag: string) => {
            return <Chip style={{marginRight: 2}} label={tag} key={tag} size='small'/>
          })}</div>
        </CardContent>
      
      <CardActions>
        {props.status === 'new' && <Button size="small" color="primary" onClick={takeCourse}>
          <T ts="takeThisCourse"/>
        </Button>}
        {(props.status === 'inProgress' || props.status === 'completed') && <Button size="small" color="primary" onClick={goToCourse}>
          <T ts="goToCourse"/>
        </Button>}
        {/* {props.status === 'inProgress' &&  <Typography variant="body2" color="textSecondary" component="p">
            IN PROGRESS
          </Typography>}
          {props.status === 'completed' &&  <Typography variant="body2" color="textSecondary" component="p">
            COMPLETED
          </Typography>} */}
          
        {/* <Button size="small" color="primary">
          Learn More
        </Button> */}
      </CardActions>
    </Card>
  );
}