import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";

import "./Occuoation.css";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0),
      width: theme.spacing(16),
      //   height: theme.spacing(16),
    },
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
    cursor: "pointer",
  },
}));

const Occupation = (props) => {
  const classes = useStyles();
  // const [languagesContext] = useContext(LanguageContext);
  const { filterOccupation, isCurrent, occupation } = props;
  return (
    <div
      className={classes.root}
      onClick={() => filterOccupation(occupation)}
      style={{ borderLeft: isCurrent ? "3px solid #ff7062" : "none" }}
    >
      <Button
        variant={isCurrent ? "text" : "text"}
        color={isCurrent ? "default" : "default"}
        disableElevation={true}
        style={{
          width: "100%",
          textAlign: "left",
          justifyContent: "start",
          textTransform: "capitalize",
          padding: "20px 13px",
        }}
      >
        <Typography variant="body2" component="div" className="occupation__wrapper">
          <div className="occupation__title">{occupation}</div>
          {isCurrent && <ArrowRightAltIcon className="occupation__icon" />}
        </Typography>
      </Button>
    </div>
  );
};

export default Occupation;
