import React, {useContext} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { LanguageContext } from '../../LanguageContext';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0),
      width: theme.spacing(16),
    //   height: theme.spacing(16),
    },
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    cursor: 'pointer'
  }
}));

export const TaskList = ({task, openTaskContent, isCompleted, isCurrent}) => {
  const classes = useStyles();
  const [languagesContext] = useContext(LanguageContext);
  const nameKey = `name_${languagesContext}`;

  return (
    <div className={classes.root} onClick={() => openTaskContent(task)}>
      <Button variant={isCurrent ? "contained" : "text"} color={isCurrent ? "default" : "primary"} disableElevation={true} style={{width: '100%', textAlign: 'left', justifyContent: 'start', textTransform: 'capitalize', padding: '20px 13px'}}>
        <Typography variant="body2">
        {isCompleted ? <span style={{
          paddingRight: '5px',
          display: 'inline-block',
          position: 'relative',
          top: '2px'}}><CheckCircleIcon color="secondary" fontSize="inherit"/></span>: null}
          {task.content[nameKey]} 
        </Typography>
      </Button>
    </div>
  );
}