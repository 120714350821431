import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import CourseCard from "../Courses/CourseCard";
import Typography from "@material-ui/core/Typography";
import { Box } from "@material-ui/core";
import { useFirebase } from "../../context/firebase";
import { readCourses } from "../../redux/reducers/courses";
import T from "../../translations/T";
import { Redirect } from "react-router-dom";
import { useAuthentication } from "../../context/authentication";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import CommonProgress from "../../components/CommonProgress";
import Occupation from "./Occupation";

import "./Home.css";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  link: {
    textDecoration: "none",
  },
  button: {
    textTransform: "inherit",
    fontWeight: 500,
    color: "#222",
    textDecoration: "none",
    marginLeft: 10,
  },
  menuButton: {
    marginRight: theme.spacing(1),
  },
  title: {
    flexGrow: 4,
  },
}));

export const Home = () => {
  const { isLogged } = useAuthentication();
  const courses = useSelector((state: any) => state.courses.list);
  const coursesInProgress = useSelector((state: any) => state.courses.inProgress);
  const coursesCompleted = useSelector((state: any) => state.courses.completed);
  const [categoryMenu, setCategoryMenu] = useState(null);
  const [currentCategory, setCurrentCategory] = useState("");
  const [courseOccupations, setCourseOccupations] = useState([]);
  const [currentOccupation, setcurrentOccupation] = useState("");
  const myCourses: any = {
    coursesInProgress,
    coursesCompleted,
  };
  const firebase = useFirebase();
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    if (!courses.length && isLogged) {
      // console.log('FETCHING COURSES...')
      firebase.firestore
        .collection("courses")
        .get()
        .then((querySnapshot: any) => {
          const courses: any[] = [];
          querySnapshot.forEach((doc: any) => {
            courses.push({ ...doc.data(), id: doc.id });
          });
          dispatch(readCourses(courses));
        });
    } else {
      console.log("COURSESL : ", courses);
      let occupations: any = new Set();
      occupations.add("All");
      courses.forEach((course: any) => {
        if (course.occupation) {
          occupations.add(...course.occupation);
        }
        console.log("occupations: ", occupations);
        setCourseOccupations(Array.from(occupations));
        const [first] = occupations;
        setcurrentOccupation(first);
      });
    }
  }, [courses]);

  const getCoursesByType = (courseType: string) => {
    let relevantCourses = [];
    if (courseType === "new") {
      const coursesToExclude = [...coursesInProgress, ...coursesCompleted];
      relevantCourses = courses.filter((course: any) => !coursesToExclude.includes(course.id));
    } else {
      relevantCourses = courses.filter((course: any) => myCourses[courseType].includes(course.id));
    }
    return relevantCourses;
  };

  const coursesRender = (relevantCourses: any, courseType: string) => {
    // console.log(relevantCourses);
    let filteredCourses = currentCategory
      ? relevantCourses.filter((course: any) => course?.category?.includes(currentCategory))
      : relevantCourses;
    if (currentOccupation && currentOccupation !== "All") {
      filteredCourses = currentOccupation
        ? filteredCourses.filter((course: any) => course?.occupation?.includes(currentOccupation))
        : filteredCourses;
    }
    return (
      <div style={{ display: "flex", justifyContent: "start", flexWrap: "wrap" }}>
        {filteredCourses.map((course: any) => {
          const props: any = {
            ...course,
            status: courseType,
          };
          return (
            <div className="home-course-card-wrapper" key={props.id}>
              <CourseCard {...props} />
            </div>
          );
        })}
      </div>
    );
  };

  // const uploadTask = () => {
  //   console.log('WORKS');
  //   const course:any = {
  //     name: 'React - TODO with classes',
  //     courseId: 'kr2wSCAlWviCp2Kj2Pu0'
  // };
  //   const doc: any = firebase.firestore.collection("tasks").doc();
  //   doc.set(course).then((data: any) => {
  //     console.log("TASK successfully written!", doc.id);
  //   });
  // }

  // const uploadCourse = () => {
  //   console.log('WORKS');
  //   const course:any = {
  //     name: 'JS Practicum',
  //     name_en: 'Javascript Exercises',
  //     name_ru: 'Javascript Задачи',
  //     imageUrl: 'https://codeway.today/wp-content/uploads/2021/08/js-practicum.jpg',
  //     description_en: 'Javscript small exercises with different level of complexity',
  //     description_ru: 'Короткие задачи по Javascript разных уровней сложности'
  // };
  //   const doc: any = firebase.firestore.collection("courses").doc();
  //   doc.set(course).then((data: any) => {
  //     console.log("COURSE successfully written!", doc.id);
  //   });
  // }

  const coursesInProgressToRender = courses && getCoursesByType("coursesInProgress");
  const coursesCompletedToRender = courses && getCoursesByType("coursesCompleted");
  const coursesNewToRender = courses && getCoursesByType("new");

  const handleCategoryMenuOpen = (event: any) => {
    setCategoryMenu(event.currentTarget);
  };

  const handleCategoryMenuClose = () => {
    setCategoryMenu(null);
  };

  const handleCategoryMenuClick = (category: string) => {
    setCategoryMenu(null);
    setCurrentCategory(category);
  };

  if (!isLogged) return <Redirect to="/login" />;

  return (
    <div className="inner-wrapper">
      {/* <button onClick={uploadCourse}>UPLOAD COURSE</button> */}
      {/* <button onClick={uploadTask}>UPLOAD TASK</button> */}
      {/* <Box>
        <CommonProgress />
      </Box> */}
      <div className="home__wrapper">
        {!!courseOccupations.length && (
          <div className="home__occupations">
            {courseOccupations.map((occupation) => (
              <Occupation
                occupation={occupation}
                isCurrent={currentOccupation === occupation}
                filterOccupation={() => setcurrentOccupation(occupation)}
              />
            ))}
          </div>
        )}
        <div className="home__main-content">
          <div className="category-filter">
            <Box>
              <Button
                aria-controls="categories"
                aria-haspopup="true"
                onClick={handleCategoryMenuOpen}
                className={classes.button}
              >
                {currentCategory === "practice" || currentCategory === "theory" ? (
                  <T ts={currentCategory} />
                ) : (
                  <span>Category</span>
                )}{" "}
                <ArrowDropDownIcon />
              </Button>
              <Menu
                id="categories"
                anchorEl={categoryMenu}
                keepMounted
                open={Boolean(categoryMenu)}
                onClose={handleCategoryMenuClose}
              >
                <MenuItem onClick={() => handleCategoryMenuClick("")}>
                  <T ts="all" />
                </MenuItem>
                <MenuItem onClick={() => handleCategoryMenuClick("practice")}>
                  <T ts="practice" />
                </MenuItem>
                <MenuItem onClick={() => handleCategoryMenuClick("theory")}>
                  <T ts="theory" />
                </MenuItem>
              </Menu>
            </Box>
          </div>
          <Box m={3}>
            <Typography gutterBottom variant="h5" component="h2">
              <T ts="coursesInProgress" />:
            </Typography>
            {coursesInProgressToRender?.length ? (
              coursesRender(coursesInProgressToRender, "inProgress")
            ) : (
              <Typography gutterBottom variant="body2">
                <T ts="noProgressCourses" />
              </Typography>
            )}
          </Box>
          <Box m={3}>
            <Typography gutterBottom variant="h5" component="h2">
              <T ts="availableCourses" />:
            </Typography>
            {coursesNewToRender?.length ? (
              coursesRender(coursesNewToRender, "new")
            ) : (
              <Typography gutterBottom variant="body2">
                <T ts="noAvailableCourses" />
              </Typography>
            )}
          </Box>
          <Box m={3}>
            <Typography gutterBottom variant="h5" component="h2">
              <T ts="completedCourses" />:
            </Typography>
            {coursesCompletedToRender?.length ? (
              coursesRender(coursesCompletedToRender, "completed")
            ) : (
              <Typography gutterBottom variant="body2">
                <T ts="noCompletedCourses" />:
              </Typography>
            )}
          </Box>
        </div>
      </div>
    </div>
  );
};
