import React, { useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { useAuthentication } from "../../context/authentication";
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {LoggedOutHome} from '../Home/LoggedOutHome';
import bgImage from '../../static/assets/yixx3.jpg';
import {useMediaQuery } from '../../hooks/mediaQuery';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: '#ff7062',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export const Register = () => {
  const { push } = useHistory();
  const { doRegister } = useAuthentication();
  const isMobileView = useMediaQuery('(max-width: 768px)');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    password: "",
  });
  const [error, setError] = useState("");
  const classes = useStyles();

  const onSubmit = async () => {
    const { name, email, password } = formValues;
    
    try {
      setIsSubmitting(true);

      await doRegister(email, password, name);

      window.location.href = "/";
    } catch (e) {
      setIsSubmitting(false);
      console.log(e);
      setError(e?.message);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError("");
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div style={{backgroundColor: "#ffffff"}}>
    <Container component='div' maxWidth='xl' style={{background: isMobileView ? '#ffffff' : "url("+bgImage+")", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "contain", paddingBottom: '50px'}}>
    <Container component='main' maxWidth='xs'>
      <CssBaseline />
      <div><img className={"logo-image"} src={require('../../static/assets/logo-black.png')} alt="" style={{width: '40%', position: 'relative', top: '3px'}}/></div>
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component='h1' variant='h5'>
          Registration
        </Typography>
        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
          <Grid item xs={12}>
              <TextField
                onChange={handleChange}
                variant='outlined'
                required
                fullWidth
                id='name'
                label='Name'
                name='name'
                autoComplete='name'
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                onChange={handleChange}
                variant='outlined'
                required
                fullWidth
                id='email'
                label='Email Address'
                name='email'
                autoComplete='email'
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                onChange={handleChange}
                variant='outlined'
                required
                fullWidth
                name='password'
                label='Password'
                type='password'
                id='password'
                autoComplete='current-password'
              />
            </Grid>
          </Grid>
          {error && <Typography variant='body2' style={{color: 'red', paddingTop: '20px'}}>
              {error}
            </Typography>
          }
          <Button
            disabled={isSubmitting}
            onClick={onSubmit}
            type='submit'
            fullWidth
            variant='contained'
            color='primary'
            className={classes.submit}
          >
            {isSubmitting ? "Registering..." : "Register"}
          </Button>
        </form>
      </div>
    </Container>

    </Container>
    <LoggedOutHome />
    </div>
  );

  return (
    <form>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div>
          <label htmlFor="email">Email</label>
          <input
            type="email"
            required
            id="email"
            name="email"
            value={formValues.email}
            onChange={handleChange}
          />
        </div>
        <div>
          <label htmlFor="password">Password</label>
          <input
            type="password"
            required
            id="password"
            name="password"
            value={formValues.password}
            onChange={handleChange}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "fit-content",
          }}
        >
          <button disabled={isSubmitting} onClick={onSubmit}>
            {isSubmitting ? "registering..." : "register"}
          </button>
          <Link to="/login">I already have an account</Link>
        </div>
      </div>
    </form>
  );
};
