import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    currentUser: null,
    inProgress: ['a123'],
    completed: ['b123']
  },
  reducers: {
    setUser: (state, action) => {
      state.currentUser = action.payload;
    },
    setCompletedTasks: (state, action) => {
      state.completedTasks = state.currentUser.completedTasks.concat(action.payload);
    },
    setTaskUncompleted: (state, action) => {
      state.completedTasks = state.currentUser.completedTasks.filter(task => task.id !== action.payload);
    },
    updateUserLaguage: (state, action) => {
      state.currentUser.userLanguge = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { setUser, setCompletedTasks, setTaskUncompleted, updateUserLaguage } = userSlice.actions;

export default userSlice.reducer;