import React, { useEffect, useState } from "react";
import { BrowserRouter, Redirect, Switch, Route } from "react-router-dom";
import { Home } from "./pages/Home";
import { Login } from "./pages/Login";
import { Register } from "./pages/Register";
import { Courses } from "./pages/Courses";
import { useAuthentication } from "./context/authentication";
import Navbar from "./components/Navbar";
import { SingleCourse } from "./pages/SingleCourse/SingleCourse";
import { LanguageContext } from './LanguageContext';
import { useSelector } from 'react-redux';
import Loader from './components/Loader';
import ForgotPassword from './pages/ForgotPassword';

export const App = () => {
  const { isFetchingUser, isLogged } = useAuthentication();
  const [languagesContext, setLanguagesContext] = useState('en');  
  const currentUser = useSelector(state => state.user.currentUser);

  useEffect(() => {
    if(currentUser) {
      setLanguagesContext(currentUser.userLanguage);
    }
  }, [currentUser]);

  if (isFetchingUser) return <Loader />;

  return (
    <LanguageContext.Provider value={[languagesContext, setLanguagesContext]}>
      {isLogged ? <AuthenticatedApp /> : <UnauthenticatedApp /> }
    </LanguageContext.Provider>
  )
};

const AuthenticatedApp = () => {
  const { isLogged } = useAuthentication();

  // if (!isLogged) return <Redirect to="/login" />;

  return (
    <BrowserRouter>
    <Navbar/>
    <div>
      <Switch>
        
        <Route path="/" exact>
          <Home />
        </Route>
        {/* <Route path="/courses" exact>
          <Courses />
        </Route> */}
        <Route path="/course/:courseId/:taskId?" exact>
          <SingleCourse />
        </Route>
        <Route exact path="/login">
            <Login />
        </Route>
        <Route path="*"><Home /></Route>
      </Switch>
    </div>
    </BrowserRouter>
  );
};

const UnauthenticatedApp = () => {
  return (
    <BrowserRouter>
      
      <Switch>
        <Route path="/" exact>
          <Home />
        </Route>
        <Route exact path={["/login"]}>
          <Login />
        </Route>
        <Route exact path="/register">
          <Register />
        </Route>
        <Route exact path="/forgot-password">
          <ForgotPassword />
        </Route>
      </Switch>
    </BrowserRouter>
  );
};
