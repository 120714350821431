import React, { createContext }  from "react";
import { BrowserRouter } from "react-router-dom";
import { FirebaseProvider } from "./context/firebase";
import { AuthenticationProvider } from "./context/authentication";
import store from './store';
import { Provider } from 'react-redux';

type RootProviderProps = {
  children: React.ReactNode;
};

export const RootProvider = ({ children }: RootProviderProps) => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <FirebaseProvider>
          <AuthenticationProvider>{children}</AuthenticationProvider>
        </FirebaseProvider>
      </BrowserRouter>
    </Provider>
  );
};
