import React, { useEffect, useState } from 'react';
import { TaskList } from './TaskList';
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { useFirebase } from '../../context/firebase';
import { mergeTasksContent, readTasks } from '../../redux/reducers/tasks';
import { TaskContent } from './TaskContent';
import { useHistory } from "react-router-dom";
import Loader from '../../components/Loader';
import AlertDialog from '../../components/AlertDialog';
import T from '../../translations/T';
import { changeCourseStatusToComplete } from '../../redux/reducers/courses';
import { useMediaQuery } from '../../hooks/mediaQuery';
import MenuIcon from '@material-ui/icons/Menu';

export const SingleCourse = () => {

    let { courseId, taskId } = useParams();
    const isMobileView = useMediaQuery('(max-width: 768px)');
    const dispatch = useDispatch();
    const firebase = useFirebase();
    const unorderedTasks = useSelector((state) => state.tasks.list);
    const fetchedCourses = useSelector((state) => state.tasks.fetchedCourses);
    const completedTasks = useSelector(state => state.tasks.completedTasks);
    const currentUser = useSelector(state => state.user.currentUser);
    const [currentTask, setCurrentTask] = useState();
    const [sidebarMenuOpen, setSidebarMenuOpen] = useState(!isMobileView);
    const { push } = useHistory();
    const [openCompleteCourseModal, setOpenCompleteCourseModal] = useState(false);
    const completedCourses = useSelector(state => state.courses.completed);
    const inProgressCourses = useSelector(state => state.courses.inProgress);
    const courseTasks = fetchedCourses.includes(courseId) ? unorderedTasks.filter(task => task.courseId === courseId) : [];
    

    useEffect(() => {
        if(!fetchedCourses.includes(courseId)) {
            // console.log('FETCHING TASKS', courseId);
            firebase.firestore.collection("tasks").where("courseId", "==", courseId).get().then((querySnapshot) => {
                const tasks = [];
                querySnapshot.forEach((doc) => {
                tasks.push({...doc.data(), id: doc.id});
                });
                dispatch(readTasks(tasks));
                // console.log('TASKS:', tasks);
                fetch(`https://codeway.today/wp-json/codeway-tasks/course/${courseId}`)
                    .then((response) => response.json())
                    .then((data) => {
                        // console.log('FETCHING TASKS FROM WP', data);
                        dispatch(mergeTasksContent({
                            courseId,
                            data
                        }));
                    });
                    
            });
        }
    }, [courseId]);

    const checkIfTasksHaveContent = () => {
        let haveContent = true;
        for(let task of courseTasks) {
            if(task && !task.content) {
                haveContent = false;
                break;
            }
        }
        return haveContent;
    }

    useEffect(() => {
        if(!taskId && courseTasks && courseTasks.length && completedTasks) {
            const uncompletedTasks = courseTasks.filter(task => !completedTasks.includes(task.id)).sort((a, b) => parseFloat(a.content.task_order_number) - parseFloat(b.content.task_order_number));
            if(uncompletedTasks.length) {
                push(`/course/${courseId}/${uncompletedTasks[0].id}`);
            } else {
                push(`/course/${courseId}/${courseTasks[0].id}`);
            }
        }
        if(courseTasks?.length) {
            const potentialCurrentTask = courseTasks.find(task => task.id === taskId);
            if(potentialCurrentTask) {
                setCurrentTask(potentialCurrentTask);
            }
        }
    }, [courseTasks, taskId, completedTasks]);
    
    if(!fetchedCourses.includes(courseId) && !taskId && !currentTask && !courseTasks.length) {
        return <div><Loader /></div>;
    }

    let tasks;
    if(courseTasks && courseTasks.length && checkIfTasksHaveContent()) {
        tasks = courseTasks.filter((task) => task.courseId === courseId).sort((a, b) => parseFloat(a.content.task_order_number) - parseFloat(b.content.task_order_number)); 
    } else {
        return (<div><Loader /></div>);
    }
    
    const openTaskContent = (task) => {
        push(`/course/${courseId}/${task.id}`);
        if(isMobileView){
            setSidebarMenuOpen(false);
        }
    }

    const goToNextTask = () => {
        const incompletedTasks = tasks.filter(task => !completedTasks.includes(task.id) && task.id !== currentTask.id);
        if(incompletedTasks.length === 0) {
            // show message you have finished a course and button "Explore new courses"
            // alert('congrats you have finished the course!')
            makeOpenCompleteCourseModal();
            firebase.firestore.collection("users").doc(currentUser.id).set({
               coursesCompleted: [...completedCourses, courseId],
               coursesInProgress: inProgressCourses.filter(course => course !== courseId)
            }, { merge: true }).then(() => {
                dispatch(changeCourseStatusToComplete(courseId));
            });
        } else {
            push(`/course/${courseId}/${incompletedTasks[0].id}`);
        }
    }

    const goToCourses = () => {
        setOpenCompleteCourseModal(false);
        push(`/`);
    }

    const closeCompleteCourseModal = () => {
        setOpenCompleteCourseModal(false);
    }
    
    const makeOpenCompleteCourseModal = () => {
        setOpenCompleteCourseModal(true);
    }

    const toggleMobileSidebar = () => {
        setSidebarMenuOpen(!sidebarMenuOpen);
    }

    return (
        <div>
            {currentTask && taskId && fetchedCourses.includes(courseId) ? 
                <div className="wrapper" style={{display: 'flex'}}>
                    { isMobileView && <div className="mobile-sidebar-hidden" onClick={toggleMobileSidebar}>
                            <MenuIcon />
                            </div> }
                    { sidebarMenuOpen && <div className="course-tasklist-wrapper">
                        <div className="course-tasklist">{tasks.map((task, i) => (<TaskList key={task.id} task={task} openTaskContent={openTaskContent} isCompleted={completedTasks.includes(task.id)} isCurrent={currentTask.id === task.id} />))}</div> 
                    </div> }
                    <div className="course-content-wrapper">
                        {currentTask && currentTask.content && <TaskContent currentTask={currentTask} isCompleted={completedTasks.includes(currentTask.id)} onTaskComplete={goToNextTask}/> }
                    </div>
                    <AlertDialog 
                        onSave={goToCourses} 
                        onCancel={closeCompleteCourseModal} 
                        isOpen={openCompleteCourseModal}
                        content={{
                            title: <T ts="congrats"/>,
                            text: <T ts="completeCourseMessage"/>,
                            saveBtn: <T ts="takeAnotherCourse"/>,
                            cancelBtn: <T ts="cancel"/>,
                        }}/>
                </div>
                : <div><Loader /></div>
            }
        </div>
    )
}